import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

// https://developer.auth0.com/resources/guides/spa/react/basic-authentication/v17-javascript-react-router-5#configure-a-react-authentication-provider
export const Auth0ProviderWithHistory = ({ children }) => {
  const queryParams = new URLSearchParams(location.search)
  const subdomain = window.location.hostname.split('.')[0]
  const history = useHistory()

  return (
    <Auth0Provider
      useCookiesForTransactions={false}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL.replace('{organizationname}', queryParams.get('organization_name') || subdomain),
        organization: queryParams.get('organization') || subdomain
      }}
      onRedirectCallback={appState => appState?.returnTo && history.push(appState?.returnTo)}
    >
      {children}
    </Auth0Provider>
  )
}
