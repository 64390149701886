export const featureFlagInit = {
  initialised: false,
  sieraplus_userAndAssetAdmin: false,
  sieraplus_fundViews: true, //todo: set back to false
  sieraplus_userGroupAdmin:false,
  sieraplus_nzc: false,
  sieraplus_nzcStatic: false,
  sieraplus_unitSystem:false,
  sieraplus_energyStarIntegration:false,
  sieraplus_currency:false,
  sieraplus_actionBasedPathways: false,
  sieraplus_scienceBasedPathways: false,
  sieraplus_assetnzc: false,
  sieraplus_consumptionBulkUpload: false,
  sieraplus_nzcFundEnergyIntensity: false,
  sieraplus_assetleveldataestimation: false,
  sieraplus_nzcAssetEnergyIntensity: false,
  sieraplus_actionPlanBulkUpload: false,
  sieraplus_AssetModification: false,
  // flags for asset action plan improvements
  sieraplus_assetActionPlan: false, // asset action plan
  sieraplus_assetActionPlanAddAction: false, // add actions
  sieraplus_assetActionPlanOperations: false, // view/edit actions
  sieraplus_consumptionOperations: false, // view/add/edit consumption
  sieraplus_sfdr: false,
  sieraplus_nzcAssetSoldAndPurchased: false,
  sieraplus_documentUpload: false,
  sieraplus_nzcUploadTargetPathway : false,
  sieraplus_portfolioLayoutV3: false,
  sieraplus_nzc_reportingYear: false,
  sieraplus_renewables: false,
  sieraplus_GBC: false,
  sieraplus_fundDataQualityLayoutV2: false,
  sieraplus_assetDataQualityLayoutV2: true,
  sieraplus_fundassetspagination: false,
  sfdr_occupancy_view: false, // SFDR Occupancy timeline view in add edit sidebar
  sieraplus_nzcassetcustomsbt: false, //Made for PS to have the ability to put 1.51 for SBT
  aeris_unified_login: false
}

export const Flags = {
  UserAndAssetAdmin: 'sieraplus_userAndAssetAdmin',
  FundViews : 'sieraplus_fundViews',
  FundReports: 'sieraplus_sfdr',
  UserGroupAdmin : 'sieraplus_userGroupAdmin',
  NZC: 'sieraplus_nzc',
  UnitSystem: 'sieraplus_unitSystem',
  CurrencyUnit: 'sieraplus_currency',
  EnergyStarIntegration : 'sieraplus_energyStarIntegration',
  AssetNZC: 'sieraplus_assetnzc',
  ConsumptionBulkUpload : 'sieraplus_consumptionBulkUpload',
  AssetGapFilling: 'sieraplus_assetleveldataestimation',
  ActionPlanBulkUpload: 'sieraplus_actionPlanBulkUpload',
  ConsumptionOperations: 'sieraplus_consumptionOperations',
  AssetModification: 'sieraplus_AssetModification',
  AssetActionPlan: 'sieraplus_assetActionPlan',
  PortfolioV3: 'sieraplus_portfolioLayoutV3',
  GBC: 'sieraplus_GBC',
  FundDataQualityV2: 'sieraplus_fundDataQualityLayoutV2',
  OccupancyView: 'sfdr_occupancy_view',
  DataQualityLayoutV2: 'sieraplus_assetDataQualityLayoutV2'
}

export const featureFlagReducer = (state, action) => {
  return {
    ...state,
    ...action
  }
}