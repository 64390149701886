import React, {useContext} from 'react'
import {RadioButton} from 'primereact/radiobutton'
import { PortfolioContext} from 'Contexts'
import {useMutation} from 'react-query'
import {updateSettings} from '../../../../services/settings-service'
import {getLocalStorageByKey, getLocalStorageItem, setLocalStorageItem} from '../../../../utils/helpers/Helper'
import {LOCALES, REV_LOCALES} from '../../../../utils/i18n/constants'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'
import PropTypes from 'prop-types'

const LanguageMenu = (props) => {
  const {loginDispatch, loginState} = useLoginAuthContext()
  const userInfo = loginState.userInfo
  const pfContext = useContext(PortfolioContext)
  const ALL = 'All'
  
  pfContext.pfState.filterState.filterFundSelected = ALL
  pfContext.pfState.filterState.filterLocationSelected = ALL
  pfContext.pfState.filterState.filterSectorSelected = ALL

  const updateLanguageMutation = useMutation(updateSettings)
  async function onLanguageSelectHandler(data) {
    let langKey = data.menudata.value
    let selectedLanguageObj = {
      languagePreference: Object.keys(LOCALES).find(key => LOCALES[key] === langKey),
      unitSystem: userInfo.unitSystem,
      currencyUnit: userInfo.currencyUnit,
      connectionStep: userInfo.connectionStep // TODO: Need to modify the PUT api for energy star connection step and this needs to discuss with the team
    }
    loginDispatch({ type: 'UPDATE_USER_INFO', payload: { languagePreference: langKey}})
    setLocalStorageItem('userInfo',{...getLocalStorageByKey('userInfo'), languagePreference : REV_LOCALES[langKey]}) //TODO: Delete this line once useLoginAuthContext is implemented across all files

    try {
      const data = await updateLanguageMutation.mutateAsync({
        userId: getLocalStorageItem('userId'),
        updatedData: selectedLanguageObj
      })
    } catch (e) {
      console.log(e.toString())
    }
  }

  return (
    <div className="grid settings-Language-menuItem">
      <div className="col-8 menuItem-title">{props.menudata.label}</div>
      <div className="col-1"/>
      <div className="col-3 p-field-radiobutton">
        <RadioButton checked={userInfo.languagePreference === props.menudata.value} value={props.menudata.label}
          onChange={() => onLanguageSelectHandler(props)}/>
      </div>
    </div>
  )
}

LanguageMenu.propTypes = {
  menudata: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
}

export default LanguageMenu