import React from 'react'
import './Terms.scss'

const Terms = () => {
  return (
    <div className="grey-bg min-width-1280">
      <div className="display gutter">
        <div className="container-box">
          <div className="terms-and-conditions">
            <h1 className="terms-title">
              EVORA Terms and Conditions for the Provision of Services
            </h1>
            <p>
              These Terms and Conditions, as referenced in the attached{' '}
              <strong>Engagement Letter</strong> (which may include a <strong>Statement of Work</strong>),
              together with all attached appendices and schedules, constitutes
              the entire <strong>Agreement</strong> in relation to the <strong>Services</strong> to be provided by
              the <strong>EVORA</strong> entity identified in the Engagement Letter, to the{' '}
              <strong>Client</strong> named in the Engagement Letter. The Services may include{' '}
              <strong>Managed Data Services</strong> and/or <strong>Software Services</strong> and/or specialist{' '}
              <strong>Consulting Services</strong>. This <strong>Engagement</strong> supersedes all previous
              agreements and understandings in relation to its subject matter.
            </p>
            <p>
              If there is any conflict between the provisions of (i) these Terms
              and Conditions and the Engagement Letter, the Engagement Letter
              shall prevail; and (ii) the Engagement Letter and any documents
              referenced in it, the Engagement Letter shall prevail
            </p>
            <ol
              type="1"
              style={{ paddingInlineStart: '0px' }}
              className="ol-decimal"
            >
              <li className="li-decimal bold">Commencement and duration </li>
              <p>This Engagement shall: </p>
              <ol type="a">
                <li className="li-alpha">
                  commence on the <strong>Commencement Date</strong> for the Services specified
                  in the Engagement Letter; and
                </li>
                <li className="li-alpha">
                  continue, unless terminated earlier in accordance with these
                  Terms and Conditions, (i) for the <strong>Initial Term</strong> which commences
                  on the Commencement Date; and (ii) any <strong>Renewal Periods</strong> which
                  commence at the end of the Initial Term or the preceding
                  Renewal Period, as specified in the Engagement Letter.
                </li>
              </ol>
              <li className="li-decimal bold">
                EVORA’s responsibilities in providing the Services
              </li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  EVORA shall supply the Services in accordance with the
                  provisions of this Engagement.
                </li>
                <li className="li-decimal">
                  EVORA shall exercise the reasonable skill and care to be
                  expected of a competent provider of services similar in scope,
                  nature and complexity to the Services. No other warranty or
                  representation, express or implied, shall apply in connection
                  with the Engagement.
                </li>
                <li className="li-decimal">
                  EVORA shall use reasonable endeavours to meet any performance
                  dates specified in the Engagement Letter, but any such dates
                  shall be estimates only and time for performance by EVORA
                  shall not be of the essence.
                </li>
                <li className="li-decimal">
                  If EVORA&apos;s performance of its obligations is prevented or
                  delayed by any act or omission of the Client, its agents,
                  subcontractors, consultants or employees, then, without
                  prejudice to any other right or remedy it may have, EVORA
                  shall be allowed an extension of time to perform its
                  obligations equal to the delay caused by the Client.
                </li>
              </ol>
              <li className="li-decimal bold">Client’s responsibilities</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  The Client shall:
                  <ol type="a">
                    <li className="li-alpha">
                      cooperate with EVORA in all matters relating to the
                      Services; and
                    </li>
                    <li className="li-alpha">
                      provide to EVORA in a timely manner all documents and
                      information required under the Engagement Letter, or
                      otherwise reasonably required by EVORA, in order to
                      provide the Services and ensure that they are accurate and
                      complete in all material respects.
                    </li>
                  </ol>
                </li>
                <li className="li-decimal">
                  The Client acknowledges that EVORA is entitled to rely upon
                  the accuracy, sufficiency and consistency of any information
                  supplied to it by the Client. EVORA shall have no liability
                  for any inaccuracies contained in any information provided by
                  the Client or any third party on behalf of the Client, and
                  including without limitation in respect of any incorrect or
                  inadequate recommendations suggested or conclusions drawn
                  during the performance of the Services based on inaccurate
                  Client information.
                </li>
                <li className="li-decimal">
                  Where the Services require EVORA to access properties owned,
                  controlled or managed by the Client, the Client shall:
                  <ol type="a">
                    <li className="li-alpha">
                      provide EVORA, in a timely manner, such access as
                      reasonably required by EVORA to provide the Services; and
                    </li>
                    <li className="li-alpha">
                      inform EVORA of any relevant issues that may affect the
                      health and safety of EVORA&apos;s personnel, in advance of
                      any site visits for the purpose of carrying out the
                      Services.
                    </li>
                  </ol>
                </li>
                <li className="li-decimal">
                  The provision of the Services is for the Client&apos;s benefit
                  only. No part of any report or advice produced by EVORA for
                  the Client shall be reproduced, transmitted, copied or
                  disclosed to any third party without the prior written consent
                  of EVORA (excluding any professional advisor of the Client in
                  which case no prior written consent of EVORA shall be
                  required), and EVORA shall not be liable to any third party
                  which relies upon any such report or advice.
                </li>
                <li className="li-decimal">
                  The Client authorises EVORA to speak to or meet with any third
                  party it may need to contact in order to provide the Services.
                  EVORA may release to such third party for the purpose of the
                  Services any information reasonably necessary to perform the
                  Services and which it has obtained during the Engagement,
                  provided that where appropriate and reasonable EVORA enters
                  into a confidentiality agreement with such third party.
                </li>
                <li className="li-decimal">
                  Where the provision of the Services will require or be aligned
                  with the procurement of products and/or services from a third
                  party, the Client agrees that it shall either:
                  <ol type="a">
                    <li className="li-alpha">
                      itself enter into an agreement with the third party
                      directly agreeing to the relevant terms and conditions of
                      the third party as they apply to the products and services
                      in question; or
                    </li>
                    <li className="li-alpha">
                      enter into an agreement with EVORA containing the relevant
                      flow-down terms and conditions of the third party as they
                      apply to the products and services in question.
                    </li>
                  </ol>
                </li>
              </ol>
              <li className="li-decimal bold">Fees and payment:</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  In consideration for the provision of the Services by EVORA,
                  the Client agrees to pay the <strong>Fees</strong> as set out in the Engagement
                  Letter.
                </li>
                <li className="li-decimal">
                  Where the Fees are based on hourly or daily rates, EVORA shall
                  be entitled to increase the applicable rates no more than once
                  per year and shall provide one month&apos;s prior notice to
                  the Client of any such change applicable to the Services.
                </li>
                <li className="li-decimal">
                  At the intervals specified in the Engagement Letter, EVORA
                  shall issue a valid tax invoice to the Client showing the
                  amount of any Fees due as well as the applicable tax.
                </li>
                <li className="li-decimal">
                  All payments shall be due within thirty (30) days following
                  the issue of an invoice, and shall be paid in full without any
                  set-off, counterclaim, deduction or withholding (other than
                  any deduction or withholding of tax as required by law).
                </li>
                <li className="li-decimal">
                  If the Client fails to pay EVORA any sum due on the due date,
                  then the Client shall be charged interest on the overdue
                  undisputed amount at the rate of 4% per annum above the Bank
                  of England’s base rate from time to time. EVORA may suspend
                  all or part of the Services until payment has been made in
                  full.
                </li>
                <li className="li-decimal">
                  Where the Fee is specified in the Engagement Letter in a
                  particular currency (<strong>First Currency</strong>) together with an exchange
                  rate (<strong>Stated FX Rate</strong>) for conversion to a different currency
                  (<strong>Second Currency</strong>), and the Client requests EVORA to invoice it
                  in the Second Currency, then EVORA shall be entitled:
                  <ol type="a">
                    <li className="li-alpha">
                      where currency fluctuations are occurring, to amend in its
                      discretion the Stated FX Rate it applies to ensure that it
                      is able to charge a Fee in the Second Currency which
                      corresponds to that which would be payable in the First
                      Currency; and
                    </li>
                    <li className="li-alpha">
                      to invoice the Client for any bank charges incurred by it
                      arising out of invoicing in the Second Currency.
                    </li>
                  </ol>
                </li>
              </ol>
              <li className="li-decimal bold">Client Data ownership and use</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  The Client shall, at all times, own the <strong>Client Data</strong> (being any
                  data stored or processed in or via any of EVORA’s <strong>Software Platforms</strong> that relates to the Client’s real estate and/or
                  infrastructure assets or that was provided or transferred into
                  EVORA’s Software Platforms by or on behalf of the Client,
                  including by EVORA staff on behalf of the Client).
                </li>
                <li className="li-decimal">
                  The Client shall have sole responsibility for the reliability,
                  integrity, accuracy and quality of the Client Data and for any
                  consents, licences or permissions which are necessary for use
                  of the Client Data in accordance with the provisions of this
                  Engagement.
                </li>
                <li className="li-decimal">
                  The Client acknowledges and agrees that the Client Data will
                  be accessible via EVORA’s Software Platforms to the Client’s{' '}
                  <strong>Authorised Users</strong> (where Software Services are being provided)
                  and to any EVORA staff who are supporting the provision of the
                  Services to the Client. “Authorised Users” are individuals who
                  have been authorised by the Client to have access to a user
                  account on the Software Services, under the organisation
                  account of the Client.
                </li>
                <li className="li-decimal">
                  The Client hereby grants EVORA a non-exclusive, royalty-free,
                  worldwide licence to use the Client Data for the purposes of:
                  <ol type="a">
                    <li className="li-alpha">providing the Services; and</li>
                    <li className="li-alpha">
                      such other purposes as may be contemplated by this
                      Engagement.
                    </li>
                  </ol>
                </li>
                <li className="li-decimal">
                  The Client represents, warrants and undertakes to EVORA that:
                  <ol type="a">
                    <li className="li-alpha">
                      it owns the Client Data and/or has the right to use the
                      Client Data as envisaged in these Terms and Conditions;
                    </li>
                    <li className="li-alpha">
                      it has the right to allow EVORA to use the Client Data to
                      the extent necessary for EVORA to perform its obligations
                      and carry out the activities envisaged by the Engagement;
                    </li>
                    <li className="li-alpha">
                      the receipt, use and onward supply by EVORA (and any other
                      permitted third party) of the Client Data shall not
                      infringe the rights, including any Intellectual Property
                      Rights (as defined below), of any third party; and
                    </li>
                    <li className="li-alpha">
                      it has, and shall at all relevant times have, full rights,
                      title and authority to grant the licences to EVORA as
                      contemplated by the Engagement.
                    </li>
                  </ol>
                </li>
                <li className="li-decimal">
                  The Client shall indemnify EVORA in full for and against all
                  claims, damages, costs, expenses or liabilities whatsoever and
                  howsoever arising incurred or suffered by EVORA, including,
                  without limitation, all legal expenses and other professional
                  fees incurred as a result of or in connection with any breach
                  by the Client of this clause. 5.6 Certain of the Client Data
                  may be processed, anonymised and aggregated within EVORA’s
                  Software Platforms such that it becomes incorporated into{' '}
                  <strong>Anonymised Data</strong> which cannot be linked to the Client or the
                  Client’s properties, and which further cannot be used (either
                  on its own or combined with other data sets) to identify
                  living individuals. The Client acknowledges and agrees that:
                  <ol type="a">
                    <li className="li-alpha">
                      EVORA may use Anonymised Client Data for any purpose,
                      including operating the Software Platforms, creating
                      benchmarks and producing reports; and
                    </li>
                    <li className="li-alpha">
                      EVORA may disclose or transfer Anonymised Data to any
                      third party, and allow such third party to use Anonymised
                      Data for any purpose.
                    </li>
                  </ol>
                </li>
              </ol>
              <li className="li-decimal bold">
                Access to, and availability of, Software Platforms
              </li>
              <span>
                Where the Client is purchasing Software Services as described in
                the Engagement Letter:
              </span>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  EVORA shall provide and make available the Software Services,
                  and grants the Client a non-exclusive, non-transferable right
                  to permit its Authorised Users to access and use the Software
                  Platforms and all related <strong>Software Documentation</strong> during the
                  term specified in, and in return of the Fees specified in, the
                  Engagement Letter, and in accordance with the provisions of
                  this Engagement; and
                </li>
                <li className="li-decimal">
                  information regarding (i) the availability of the Software
                  Services; (ii) EVORA’s provision of support services in
                  relation to the Software Services; and (iii) the issue of new
                  releases and versions of the Software Platforms, may be found
                  at {' '}
                  <a
                    href="https://evoraglobal.com/wp-content/uploads/2024/12/Dec24-General-Software-external-for-URL.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://evoraglobal.com/wp-content/uploads/2024/12/Dec24-General-Software-external-for-URL.pdf.
                  </a>
                </li>
              </ol>
              <li className="li-decimal bold">
                Client accounts on Software Platforms and acceptable use
              </li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  The Client is solely responsible for all activities that occur
                  under its Authorised Users’ accounts. The Client shall ensure
                  that its Authorised Users keep passwords for accessing the
                  Software Services safe and secure and comply with any other
                  account access security features notified to them. The Client
                  shall notify EVORA immediately if it becomes aware of a
                  security breach relating to an account of any of its
                  Authorised Users.
                </li>
                <li className="li-decimal">
                  The Client is responsible for managing, and keeping updated,
                  the list of Authorised Users that have access to the
                  Client&apos;s account on the Software Services and requesting
                  that EVORA delete user accounts of any Authorised User who
                  should no longer have such access, for example where they have
                  left the employment of the Client.
                </li>
                <li className="li-decimal">
                  The Client is responsible for notifying EVORA as soon as is
                  reasonably practicable if it becomes aware of an error or any
                  other circumstances which might materially affect the
                  operation of the Software Platforms.
                </li>
                <li className="li-decimal">
                  The Client is responsible for ensuring that the Software
                  Platforms are used in a proper manner by competent trained
                  persons only or by persons under their supervision.
                </li>
                <li className="li-decimal">
                  The Client shall use the Software Services in accordance with
                  the <strong>Acceptable Use Policy</strong> set out in {' '}
                  <a
                    href="https://evoraglobal.com/wp-content/uploads/2024/12/Dec24-General-Software-external-for-URL.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://evoraglobal.com/wp-content/uploads/2024/12/Dec24-General-Software-external-for-URL.pdf
                  </a> and shall take reasonable steps to ensure that its Authorised Users are aware of and comply with the terms of the Acceptable Use Policy when using the Software Services.
                </li>
              </ol>
              <li className="li-decimal bold">Change requests</li>
              <p>
                Either party may propose changes to the scope or execution of
                the Services but no proposed changes shall come into effect
                until a <strong>Change Request</strong> has been signed by both parties. A Change
                Request shall set out the proposed changes, and the effect these
                will have on the Services, the Fees, the timetable for delivery
                of the Services, and any of the terms of the Engagement.
              </p>
              <li className="li-decimal bold">Compliance with laws</li>
              <p>
                In performing its obligations under this Agreement, each party
                shall comply with all <strong>Applicable Laws</strong> (i.e. applicable
                legislation, statutes, regulations and codes of practice) in
                force from time to time.
              </p>
              <li className="li-decimal bold">Non-solicitation</li>
              <ol className="oi-decimal">
                <li className="li-decimal">
                  Neither party shall offer employment to any employee of the
                  other party involved in the Engagement or induce or solicit
                  any such employee to take up employment for a period of 6
                  months following the end of any involvement by that employee
                  with the Engagement, provided this shall not prevent (i)
                  either party from employing any person who contacts that party
                  on his or her own initiative without any direct or indirect
                  solicitation from the employing party; and (ii) the use by
                  either party of an independent employment agency (so long as
                  it is not directed to solicit such persons), advertisements in
                  publications or other general solicitations for employment not
                  directed at such persons.
                </li>
                <li className="li-decimal">
                  In the event that a party breaches this non-solicitation
                  clause, the other party shall be entitled to be paid
                  compensation by the first party equal to 6 months’ salary of
                  the employee concerned.
                </li>
              </ol>
              <li className="li-decimal bold">Intellectual property</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  For purposes of this clause, <strong>Intellectual Property Rights</strong>{' '}
                  means all patents, rights to inventions, copyright and related
                  rights, trade marks, business names and domain names, rights
                  in get-up, goodwill and the right to sue for passing off,
                  rights in designs, database rights, rights to use, and protect
                  the confidentiality of, confidential information (including
                  know-how), and all other intellectual property rights, in each
                  case whether registered or unregistered and including all
                  applications and rights to apply for and be granted, renewals
                  or extensions of, and rights to claim priority from, such
                  rights and all similar or equivalent rights or forms of
                  protection which subsist or will subsist now or in the future
                  in any part of the world.
                </li>
                <li className="li-decimal">
                  All Intellectual Property Rights in and to all <strong>Deliverables</strong>{' '}
                  produced specifically by EVORA for the Client in the
                  performance of and as an output of the Services, shall be
                  assigned to and shall vest in the Client.
                </li>
                <li className="li-decimal">
                  For the avoidance of doubt, all Intellectual Property Rights
                  that are owned by or licensed to EVORA and which are or have
                  been developed independently of this Engagement, either
                  subsisting in the Deliverables, created or used in the
                  preparation or compilation of the Deliverables or otherwise
                  necessary or desirable to enable the Client to receive and use
                  the Services (<strong>Background IP</strong>), shall remain vested in EVORA and
                  shall not be assigned to the Client. Background IP shall also
                  include without limitation all know-how, methodologies,
                  processes, templates and technologies of EVORA as well as any
                  models which have been prepared as part of the Services. The
                  Client is granted a perpetual, non-transferable, non-exclusive
                  licence to use the Background IP solely for purposes of
                  enabling it to use the Deliverables for purposes related to
                  the performance of the Services under this Engagement.
                </li>
                <li className="li-decimal">
                  The Client acknowledges and agrees that EVORA and/or its
                  licensors own all Intellectual Property Rights in the Software
                  Platforms and the Software Documentation, including any and
                  all derivative works and improvements thereto.
                </li>
                <li className="li-decimal">
                  EVORA warrants that it has all the rights in relation to the
                  Software Platforms and the Software Documentation that are
                  necessary to grant all the rights it purports to grant under,
                  and in accordance with, this Engagement.
                </li>
                <li className="li-decimal">
                  Subject to the Limitation of Liability clause below, EVORA
                  shall indemnify the Client against any and all third party
                  actions, claims, liabilities, losses, damages, costs or
                  expenses (<strong>Liabilities</strong>) suffered or incurred by the Client
                  arising out of any actual or alleged infringement of the
                  Intellectual Property Rights of a third party relating to or
                  in respect of the Software Platforms or Software
                  Documentation, provided that:
                  <ol type="a">
                    <li className="li-alpha">
                      the Liabilities do not arise directly as a result of: (i)
                      the modification or alteration of the Software Platforms
                      by the Client (except where authorised by or envisaged by
                      EVORA); (ii) use of the Software Platforms in breach of
                      these Terms and Conditions; (iii) combining the Software
                      Platforms with a service, software or equipment not
                      provided by EVORA; or (iv) the Client’s or an Authorised
                      User’s failure to implement any updates, releases or fixes
                      for the Software Platforms provided by EVORA;
                    </li>
                    <li className="li-alpha">
                      the Client shall promptly notify EVORA in writing of any
                      Liabilities (giving reasonable detail);
                    </li>
                    <li className="li-alpha">
                      the Client shall not make any admission of liability,
                      agreement or compromise in relation to any action or claim
                      without the prior written consent of EVORA (not to be
                      unreasonably withheld);
                    </li>
                    <li className="li-alpha">
                      the Client shall give EVORA and its professional advisers
                      access (on reasonable prior notice) to its officers,
                      directors, employees, agents, representatives or advisers,
                      and to any relevant documents and records within the power
                      or control of the Client, to enable EVORA and its
                      professional advisers to examine them and to take copies
                      for the purpose of assessing the action or claim; and
                    </li>
                    <li className="li-alpha">
                      subject to EVORA providing security to the Client&apos;s
                      reasonable satisfaction against any claim, liability,
                      costs, expenses, damages or losses which may be incurred,
                      the Client shall take such action as EVORA may reasonably
                      request to avoid, dispute, compromise or defend the action
                      or claim.
                    </li>
                  </ol>
                </li>
              </ol>
              <li className="li-decimal bold">Personal data</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  Each party shall ensure that it complies with, and where
                  relevant provide reasonable assistance to the other party to
                  comply with, all <strong>Applicable Data Protection Laws</strong> in force from
                  time to time relating to any personal data that is processed
                  or transferred in connection with the Engagement.
                </li>
                <li className="li-decimal">
                  It is acknowledged that as of the Commencement Date neither
                  party acts as data processor on behalf of the other. If at any
                  point during the term of the Engagement, either party
                  considers that one party is acting as data processor on behalf
                  of the other, then the parties will negotiate in good faith a
                  separate data processing agreement to cover the matters
                  required by Applicable Data Protection Laws.
                </li>
                <li className="li-decimal">
                  EVORA’s privacy notice regarding the manner in which it
                  collects, stores and processes personal data in its
                  performance of the Services, may be found at {' '}
                  <a
                    href="https://evoraglobal.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://evoraglobal.com/privacy-policy/.
                  </a>
                </li>
              </ol>
              <li className="li-decimal bold">Confidentiality</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  EVORA shall seek the Client’s consent prior to announcing
                  through advertising, and/or by references in proposals or
                  submissions to prospective clients, that it is providing or
                  has provided the Services to the Client.
                </li>
                <li className="li-decimal">
                  The Client shall keep confidential and not disclose to any
                  third party (whether during or after the Engagement): (a) any
                  information received by it in respect of the methodologies
                  and/or technologies used by EVORA in providing the Services;
                  (b) the details of the commercial terms on which EVORA
                  provides the Services; and (c) any other information in
                  respect of EVORA’s, or its affiliates’, licensors’, vendors’
                  or business partners’ business activities which comes into its
                  possession as a consequence of EVORA providing the Services
                  and which is not publicly available.
                </li>
                <li className="li-decimal">
                  EVORA shall keep confidential and not disclose to any third
                  party (whether during or after the Engagement) any information
                  in respect of the Client’s properties or business activities
                  (including all Client Data) which comes into its possession as
                  a consequence of EVORA providing the Services and which is not
                  publicly available.
                </li>
                <li className="li-decimal">
                  Notwithstanding the above:
                  <ol type="a">
                    <li className="li-alpha">
                      either party may share the other party&apos;s information
                      with its employees, officers, representatives,
                      subcontractors or professional advisers (<strong>Representatives</strong>)
                      who need to know such information for the purposes of
                      carrying out the party&apos;s obligations under the
                      Engagement provided that those Representatives are subject
                      to an obligation of confidentiality and the party shall
                      remain liable for any failure by its Representatives to
                      comply with the obligations set out in this clause;
                    </li>
                    <li className="li-alpha">
                      either party may disclose the other party&apos;s
                      information as required by law, a court of competent
                      jurisdiction or any governmental or regulatory authority;
                    </li>
                    <li className="li-alpha">
                      the Client acknowledges that Client Data will be available
                      to its Authorised Users and to EVORA employees via the
                      Software Platforms;
                    </li>
                    <li className="li-alpha">
                      EVORA may disclose or transfer Client Data to third
                      parties (for example GRESB) where specifically requested
                      by, or authorised in writing in advance by, the Client;
                      and
                    </li>
                    <li className="li-alpha">
                      EVORA may disclose Anonymised Data to third parties as
                      envisaged above.
                    </li>
                  </ol>
                </li>
                <li className="li-decimal">
                  This clause shall survive for a period of 2 years from
                  termination or expiry of the Engagement.
                </li>
              </ol>
              <li className="li-decimal bold">Insurance</li>
              <p>
                During the term of the Engagement, EVORA shall maintain in
                force, with a reputable insurance company, the insurance
                coverage as specified in the Engagement Letter and shall produce
                to the Client on request documentary evidence that this cover is
                being properly maintained.
              </p>
              <li className="li-decimal bold">Limitation on liability</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  Nothing in the Agreement shall limit or exclude the liability
                  of: (i) either party for death or personal injury caused by
                  its negligence, or the negligence of its personnel, agents or
                  sub-contractors; (ii) either party for wilful misconduct,
                  fraud or fraudulent misrepresentation; (iii) the Client under
                  the indemnities given in relation to Client Data; and (iv)
                  either party for any other liability which cannot be limited
                  or excluded by applicable law.
                </li>
                <li className="li-decimal">
                  Subject to the above:
                  <ol type="a">
                    <li className="li-alpha">
                      neither party shall have any liability to the other party,
                      whether in contract, tort (including negligence), breach
                      of statutory duty, or otherwise, for any: (i) loss of
                      profit, loss of revenue, loss of business opportunities,
                      loss of anticipated savings, or loss or corruption of
                      data; or (ii) indirect or consequential loss; arising
                      under or in connection with this Engagement; and
                    </li>
                    <li className="li-alpha">
                      the total aggregate liability of either party to the other
                      in respect of all other loss or damage arising under or in
                      connection with the Engagement, whether in contract, tort
                      (including negligence), breach of statutory duty, or
                      otherwise shall be limited to the amount specified in the
                      Engagement Letter, provided that this shall not exceed two
                      million pounds (£2 million).
                    </li>
                  </ol>
                </li>
              </ol>
              <li className="li-decimal bold">Termination and consequences</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  Neither party may terminate the Engagement so that it expires
                  during the Initial Term. Either party may terminate the
                  Engagement so that it expires at the end of the Initial Term
                  or the end of any Renewal Period, by providing the other party
                  with not less than 90 days’ prior notice in writing.
                </li>
                <li className="li-decimal">
                  Without prejudice to any other rights or remedies to which the
                  parties may be entitled, either party may terminate the
                  Engagement with immediate effect by giving written notice to
                  the other party if:
                  <ol type="a">
                    <li className="li-alpha">
                      the Client fails to pay any amount due on the due date for
                      payment and remains in default not less than 20 days after
                      being notified in writing to make such payment;
                    </li>
                    <li className="li-alpha">
                      the other party commits a material breach of any of the
                      terms of the Engagement and (if such a breach is
                      remediable) fails to remedy that breach within 30 days of
                      being notified in writing of the breach; or
                    </li>
                    <li className="li-alpha">
                      the other party becomes insolvent or takes or has taken
                      against it any step or action in connection with its
                      entering into administration, provisional liquidation or
                      any composition or arrangement with its creditors (other
                      than in relation to a solvent restructuring), or is wound
                      up (whether voluntarily or by order of the court, unless
                      for the purpose of a solvent restructuring), or has a
                      receiver appointed over any of its assets or ceases to
                      carry on business.
                    </li>
                  </ol>
                </li>
                <li className="li-decimal">
                  On termination or expiry of the Engagement for any reason: (i)
                  the Client shall immediately pay to EVORA all of the
                  EVORA&apos;s outstanding unpaid invoices. EVORA may also
                  submit an invoice for any Services supplied but which have not
                  yet been invoiced, which shall be payable immediately upon
                  receipt; (ii) EVORA shall on request of the Client return or
                  securely destroy any of the confidential information of the
                  Client in its possession; (iii) EVORA shall have the right to
                  retain and continue to use the Anonymised Data; and (iv)
                  EVORA’s policies regarding records management and retention
                  shall apply.
                </li>
                <li className="li-decimal">
                  For a period of 60 days after termination or expiry of the
                  Engagement, EVORA shall continue to provide the Client with
                  access to the Software Platforms to allow the Client to
                  download all Client Data. On request of the Client, if
                  received within 60 days of termination or expiry of the
                  Engagement, EVORA shall transfer to the Client a copy of all
                  Client Data either via an SQL database backup, Excel or CSV
                  file.
                </li>
                <li className="li-decimal">
                  On termination or expiry of the Engagement, any provision that
                  is intended to come into or continue in force shall remain in
                  full force and effect. Termination or expiry of the Agreement
                  shall not affect any rights, remedies, obligations or
                  liabilities of the parties that have accrued up to that date.
                </li>
              </ol>
              <li className="li-decimal bold">Force majeure</li>
              <p>
                Except for the Client’s payment obligations, neither party shall
                be liable for any delay or failure in the performance of its
                obligations for so long as and to the extent that such delay or
                failure results from events, circumstances or causes beyond its
                reasonable control. If the period of delay or non-performance
                continues for longer than 60 days, the party not affected may
                terminate this Engagement immediately by giving written notice
                to the affected party.
              </p>
              <li className="li-decimal bold">
                Governing law and jurisdiction
              </li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  The Agreement and any dispute or claim arising out of or in
                  connection with it or its subject matter or formation
                  (including non-contractual disputes or claims) shall be
                  governed by and construed in accordance with the laws of
                  England and Wales.
                </li>
                <li className="li-decimal">
                  Each party irrevocably agrees that the courts of England and
                  Wales shall have exclusive jurisdiction to settle any dispute
                  or claim arising out of or in connection with this Engagement
                  or its subject matter or formation (including non-contractual
                  disputes or claims).
                </li>
              </ol>
              <li className="li-decimal bold">General</li>
              <ol className="ol-decimal">
                <li className="li-decimal">
                  Neither party may assign, transfer, charge or otherwise deal
                  with all or any of its rights or obligations under this
                  Agreement without the prior written consent of the other
                  party, save that EVORA shall be entitled to use resources and
                  personnel from any of its group companies to support the
                  performance of the Services.
                </li>
                <li className="li-decimal">
                  No variation of this Agreement shall be effective unless it is
                  in writing and signed by both parties
                </li>
                <li className="li-decimal">
                  If any provision is or becomes invalid, illegal or
                  unenforceable, it shall be deemed modified to the minimum
                  extent necessary to make it valid, legal and enforceable. If
                  such modification is not possible, the relevant provision
                  shall be deemed deleted and shall be replaced by an
                  enforceable one that comes closest to the intent of the
                  parties underlying such provision. Any modification to or
                  deletion of a provision under this clause shall not affect the
                  validity and enforceability of the rest of the Agreement.
                </li>
                <li className="li-decimal">
                  A waiver of any right or remedy is only effective if given in
                  writing, and shall not be deemed a waiver of any subsequent
                  right or remedy. A delay or failure to exercise, or the
                  partial exercise of, any right or remedy does not waive or
                  prevent or restrict the further exercise of that or any other
                  right or remedy.
                </li>
                <li className="li-decimal">
                  Nothing in this Engagement shall create or be deemed to create
                  a partnership, joint venture or agency relationship between
                  the parties.
                </li>
                <li className="li-decimal">
                  This Agreement shall not create any rights that shall be
                  enforceable by anyone other than its parties or any person to
                  whom it is lawfully assigned.
                </li>
                <li className="li-decimal">
                  All notices relating to this Engagement must be in writing and
                  sent to the address specified in the Engagement Letter, unless
                  a party designates in writing a different address. The parties
                  consent to the use of electronic means for communications as a
                  signed writing.
                </li>
              </ol>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms