import React, {useContext, useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import './Header.scss'
import logo from '../../../resources/images/header/siera-logo-sand.svg'
import tasks from '../../../resources/images/icon/Notifications-Icon.svg'
import helpIcon from '../../../resources/images/icon/Help-Icon.svg'
import settingsClose from '../../../resources/images/icon/settings-close.svg'
import closeGray from '../../../resources/images/icon/close-gray.svg'
import externalLink from '../../../resources/images/icon/external-link-icon.svg'
import {FeatureFlagContext} from 'Contexts'
import I18n from '../../../utils/i18n/I18n'
import {Button} from 'primereact/button'
import {OverlayPanel} from 'primereact/overlaypanel'
import {GetUsersConfigurationData, Logout} from '../../../services/login-service'
import Avatar from 'react-avatar'
import {portfolioStateInit} from '../../../utils/reducers/PortfolioReducer'
import {
  createAndSaveCustomDefaultDate,
  createNavURLForFundPages,
  createNavURLForUserPages,
  getInstance,
  getLocalizedValue,
  getLocalStorageByKey,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../utils/helpers/Helper'
import {
  ADMIN_PAGES,
  ASSET_PAGES,
  FY_START_FOR_ASSET_ACCESS,
  LOCAL_STORAGE_KEYS,
  minWidth1280,
  minWidth760, RELEASE_NOTES,
  SUPPORT_GUIDES,
  USER_FUND_ACCESSES
} from '../../../utils/helpers/Constants'
import CSS_VARIABLES from '../../../resources/css/_variables.scss'
import SettingSidebar from './SettingSidebar'
import { useLoginAuthContext } from 'components/pages/login/auth0/UserInfoProvider'

const Header = () => {
  const history = useHistory()
  const [visibleRight, setVisibleRight]             = useState(false)
  const [showAsset, setShowAsset]                     = useState(false)
  const op = useRef(null)
  const { loginState: {userInfo, user}} = useLoginAuthContext()
  const selectedLanguage          = userInfo.languagePreference
  const loginContext              = useLoginAuthContext()
  const featureContext            = useContext(FeatureFlagContext)
  const featureState              = featureContext.featureState
  const CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY='header-highlight-clickable-opacity'
  const location = useLocation()
  const currentPage = location.pathname
  const userFundAccesses = getLocalStorageItem('userFundAccessType')
  const [headerMinWidth, setHeaderMinWidth] = useState(minWidth1280)
  const targetBlank = '_blank'
  const noOpener = 'noopener'

  useEffect(() => {
    switch(currentPage) {
    case('/login'):
    case('/notfound'):
    case('/expired-password'):
    case('/forgot-password'):
    case('/forgot-password-success'):
    case('/signUp'):
    case('/reset-password'):
    case('/reset-password-success'):
    case('/account-locked'):
      setHeaderMinWidth(minWidth760)
      break
    default:
      setHeaderMinWidth(minWidth1280)
    }
  }, [currentPage])

  useEffect(() => {
    if(userFundAccesses !== USER_FUND_ACCESSES.NoFundAccess && getLocalStorageByKey('userInfo')){
      setShowAsset(true)
    }else{
      setShowAsset(false)
    }
  },[location?.pathname,userFundAccesses])

  function openHome() {
    if (loginContext.loginState.isLoggedIn) {
      const homePath = loginContext.loginState.homePath
      if(homePath) {
        history.push(homePath)
      }
    }
  }

  function openNotification() {
    history.push('/notification')
  }

  function openSupportGuides() {
    const pageSelector = document.querySelector('[data-page_identifier]')
    const pageIdentifier = pageSelector?.dataset?.page_identifier

    switch (pageIdentifier) {
    case('organisations_list'):
      window.open(SUPPORT_GUIDES.organisationsList, targetBlank, noOpener)
      break
    case('funds_list'):
      window.open(SUPPORT_GUIDES.fundsList, targetBlank, noOpener)
      break
    case('fund_dashboard'):
      window.open(SUPPORT_GUIDES.fundDashboard, targetBlank, noOpener)
      break
    case('fund_performance'):
      window.open(SUPPORT_GUIDES.fundPerformance, targetBlank, noOpener)
      break
    case('fund_data_quality'):
      window.open(SUPPORT_GUIDES.fundDataQuality, targetBlank, noOpener)
      break
    case('fund_action_plan'):
      window.open(SUPPORT_GUIDES.fundActionPlan, targetBlank, noOpener)
      break
    case('asset_portfolio'):
      window.open(SUPPORT_GUIDES.assetPortfolio, targetBlank, noOpener)
      break
    case('asset_dashboard'):
      window.open(SUPPORT_GUIDES.assetDashboard, targetBlank, noOpener)
      break
    case('asset_performance'):
      window.open(SUPPORT_GUIDES.assetPerformance, targetBlank, noOpener)
      break
    case('asset_data_quality'):
      window.open(SUPPORT_GUIDES.assetDataQuality, targetBlank, noOpener)
      break
    case('asset_action_plan'):
      window.open(SUPPORT_GUIDES.assetActionPlan, targetBlank, noOpener)
      break
    case('asset_passport'):
      window.open(SUPPORT_GUIDES.assetPassport, targetBlank, noOpener)
      break
    default:
      window.open(SUPPORT_GUIDES.guides, targetBlank, noOpener)
    }
  }

  function openReleaseNotes() {
    return window.open(RELEASE_NOTES.releaseVersion, targetBlank, noOpener)
  }

  function LogoutUser() {
    Logout()
    setVisibleRight(false)
    loginContext.logOutOfAuthAndApp()
  }

  function usersNavigate() {
    const userHomePath=createNavURLForUserPages(
      {instanceName: getInstance(),pathSuffix: ADMIN_PAGES.users })
    setVisibleRight(false)
    history.push(userHomePath)
  }

  function groupsNavigate() {
    const groupsHomePath=createNavURLForUserPages(
      {instanceName: getInstance(),pathSuffix: 'groups' })
    setVisibleRight(false)
    history.push(groupsHomePath)
  }

  async function energyStarNavigate() {
    setVisibleRight(false)
    // SPR-3762: The reason for calling the SETTINGS API here is if the user does not logout and navigates to the ES integration setup page from the settings sidebar in other page so as to update the connection step in local storage.
    let userConfigData = await GetUsersConfigurationData()
    setLocalStorageItem('userInfo', {...getLocalStorageByKey('userInfo'), connectionStep: userConfigData.connectionStep})
    history.push('/energy-star-integration')
  }

  function navToUser() {
    setVisibleRight(false)
    history.push({pathname: createNavURLForUserPages(
      {pathPrefix: ADMIN_PAGES.users, userId: getLocalStorageItem('userId')})})
  }

  function navToMyGroups() {
    setVisibleRight(false)
    const groupsHomePath=createNavURLForUserPages(
      {instanceName: getInstance(),pathSuffix: 'groups', userId: getLocalStorageItem('userId') })
    history.push(groupsHomePath)
  }

  function gotoAssetsPage() {
    const dpCustomTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.dpCustomTimePeriod))
    const dpTime = localStorage.getItem(LOCAL_STORAGE_KEYS.dpTimePeriod)
    if(!dpTime && !dpCustomTime){
      createAndSaveCustomDefaultDate(FY_START_FOR_ASSET_ACCESS)
    }
    history.push({
      pathname: createNavURLForFundPages({instanceName: getInstance(), pathSuffix: ASSET_PAGES.assets}),
      state: portfolioStateInit,
      navFrom: 'assets-link'
    })
  }

  function goToDocumentsPage() {
    history.push('/documents')
  }

  function goToCertificatesPage() {
    history.replace('/certificates')
  }

  function goToAeris() {
    window.location.href = process.env.REACT_APP_AERIS_URL
  }

  function goToMetry() {
    console.log(loginContext.loginState.user.metry)
  }

  function handleSettingsClose() {
    setVisibleRight(false)
  }

  function navigationMenu() {
    return (<>
      {(showAsset || featureState.sieraplus_documentUpload) &&
          <span className="header-menu-separator"/>}
      {showAsset &&
          <Button role={'asset-navigation'} label={I18n('t_asset_s')} className="p-button-link header-menu-button"
            onClick={gotoAssetsPage}/>
      }
      {featureState.sieraplus_documentUpload &&
          <Button role={'documents-navigation'} label={I18n('t_documents')}
            className={'p-button-link header-menu-button' + (showAsset ? ' ml-4' : '')}
            onClick={goToDocumentsPage}/>}
      {featureState.sieraplus_GBC &&
          <Button role={'documents-navigation'} label={I18n('t_certificates')}
            className={'p-button-link header-menu-button' + (showAsset ? ' ml-4' : '')}
            onClick={goToCertificatesPage}/>}
      {featureState.aeris_unified_login && loginContext.loginState.user.siera_user &&
          <Button role={'aeris-navigation'} label="AERIS"
            className={'p-button-link header-menu-button' + (showAsset ? ' ml-4' : '')}
            onClick={goToAeris}/>}
      {featureState.aeris_unified_login && loginContext.loginState.user.metry_user &&
          <Button role={'metry-navigation'} label="Metry"
            className={'p-button-link header-menu-button' + (showAsset ? ' ml-4' : '')}
            onClick={goToMetry}/>}
    </>)

  }

  function settingCloseIcon() {
    return visibleRight ? <img role={'settings-close'} src={settingsClose}
      alt={getLocalizedValue(selectedLanguage, 't_settings')} onClick={handleSettingsClose}/> :
      getLocalStorageItem('userName') &&
        <Avatar className="header-react-avatar" name={getLocalStorageItem('userName')}
          src={user.picture} round={true} textSizeRatio={1.5}
          color={CSS_VARIABLES.foliage} fgColor={CSS_VARIABLES.deep_ocean} onClick={() => setVisibleRight(true)}/>
  }

  return (
    <div className="header-home">
      <div className={`header-wrapper gutter min-width-${headerMinWidth} dark-blue-bg mt-0 mb-0`}>
        <div role={'siera-logo'} onClick={openHome}>
          <img src={logo} alt="siera" className={`logo ${CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY}`}/>
        </div>
        <div className="header-menu">
          {navigationMenu()}
        </div>
        <div className={`header_nav ${loginContext.loginState?.isLoggedIn || loginContext.loginState?.loading ? '' : 'hide'}`}>
          <span role={'header-notification'} className="user-margin-left icon-setting">
            <img title={getLocalizedValue(selectedLanguage, 't_notifications')} className={`header-icon noti-icon ${CLASS_HEADER_HIGHLIGHT_CLICKABLE_OPACITY}`} src={tasks}
              alt={getLocalizedValue(selectedLanguage, 't_notifications')} onClick={openNotification}/>
          </span>
          <span role={'header-help'} className="icon-setting helper-icon relative">
            <img className="header-icon" src={helpIcon} alt="help-icon" onClick={(e) => op.current.toggle(e)} />
            <OverlayPanel appendTo='self' role={'help-description'} className="help-overlay" ref={op} style={{width: '14em'}}>
              <div className="help-block flex">{getLocalizedValue(selectedLanguage, 't_help')} <img onClick={(e) => op.current.hide(e)} src={closeGray} alt={'close-help'} className={'help-action-icon'} /></div>
              <div onClick={openSupportGuides} className="help-block-action flex">{getLocalizedValue(selectedLanguage, 't_support_guides')} <img src={externalLink} alt={'external-link'} className={'help-action-icon'} /></div>
              <div onClick={openReleaseNotes} className="help-block-action flex">{getLocalizedValue(selectedLanguage, 't_release_notes')} <img src={externalLink} alt={'external-link'} className={'help-action-icon'} /></div>
            </OverlayPanel>
          </span>
          <span role={'header-settings'} className="user user-margin-left sidebar-section">
            <SettingSidebar groupsNavigate={groupsNavigate} navToUser={navToUser} navToMyGroups={navToMyGroups}
              isVisible={handleSettingsClose} visibleRight={visibleRight} logoutUser={LogoutUser}
              usersNavigate={usersNavigate} energyStarNavigate={energyStarNavigate}
            />
            <span className="header-avatar">
              {settingCloseIcon()}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Header
