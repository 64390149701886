import { CAPITAL_LETTER_REGEX } from './Constants'
import { getLocalizedValue } from './LanguageUtils'

export const getEmptyRows = (numberOfLines) => {
  let emptyRowsArray = []
  for (let i = 0; i < numberOfLines; i++) {
    emptyRowsArray.push({A: ''})
  }
  return emptyRowsArray
}

export const getTitleObject = (tableTitle, selectedLanguage) => {
  return {A: getLocalizedValue(selectedLanguage, tableTitle)}
}

export const formatData = (value, localize, multiplyByHundred = false, useSplitStringCapitalization = true) => {
 
  if (typeof value === 'number' && value) {
    if (multiplyByHundred) {
      return (value * 100).toFixed(1)
    } else {
      return value.toFixed(2)
    }
  }

  switch (value) {
  case true :
    return localize('t_yes')
  case false :
    return localize('t_no')
  case undefined :
  case null:
  case '':
    return '-'
  case 0:
    return 0
  default :
    if(value instanceof Array && value.length > 0){
      value = value.join(', ')
    }
    else {
      value = value.toString().trim()
    }
    
    switch (value) {
    case 'nan':
    case 'NotSpecified':
    case 'unknown':
    case '':
    case 'none':
      return '-'
    default:
      return useSplitStringCapitalization? splitStringOnCapitalization(value) : value
    }
  }
}

//TO-DO: splitStringOnCapitalization doesnt always work as expected. For now used a flag as fix.
// Obsolete - use formatData
export const getValidData = (value, selectedLanguage, multiplyByHundred = false, useSplitStringCapitalization = true) => {
  return formatData(value, (id) => getLocalizedValue(selectedLanguage, id), multiplyByHundred, useSplitStringCapitalization)
}

const boldCondition = (length, info, key, error, explicitUnbold) => 
  explicitUnbold ? 
    false :
    length === 1 && !info[key]?.includes(':') && !info[key]?.includes(error)

export const excelHelpers = (excelDataArray, excelSheet, error, selectedLanguage, horizontalAlignment = 'center', explicitUnbold = false) => {
  excelDataArray.forEach((info, row) => {
    const keys = Object.keys(info)
    const length = keys.length

    keys.forEach((key, column) => {
      const bold = boldCondition(length, info, key, error, explicitUnbold)
      const styleCondition = length > 1
      const cell = excelSheet.cell(row + 1, column + 1)
      excelSheet.column(cell.columnNumber()).width(25)
      const convertedString = String(info[key])
      const digitsExceedingLength10 = !convertedString.includes('.') && convertedString.length > 10 ? convertedString : Number(info[key]) // To make sure that meter name is not number when only it has number as 1234556234123
      cell.value(Number(info[key]) || info[key] === '0.00' ? digitsExceedingLength10 : info[key]).style({ horizontalAlignment: styleCondition && horizontalAlignment, wrapText: styleCondition, bold: bold, border: styleCondition, fontSize: bold ? 16 : 12 })
      if (selectedLanguage) {
        const error_1 = getLocalizedValue(selectedLanguage, 't_no_meter_data_available')
        const error_2 = getLocalizedValue(selectedLanguage, 't_no_export_data_available_for_single_asset')
        const columnKey = findErrorMessage(info, error_1) || findErrorMessage(info, error_2)
        if (columnKey && key === columnKey[0]) {
          const rowNumber = row + 1
          const range = `${columnKey[0]}${rowNumber}:${columnIndexToLetter(length)}${rowNumber}`
          const errors = [error_1, error_2]
          const errorForSingleAssetIndex = errors.findIndex((err) => err === columnKey[1])
          excelSheet.range(range).value(errors[errorForSingleAssetIndex]).merged(true)
        }
      }
    })
  })
}

export const excelHelpersFieldDesc = (excelDataArray, excelSheet, error, horizontalAlignment = 'center' ) => {
  excelDataArray.forEach((info, row) => {
    const keys = Object.keys(info)
    const length = keys.length
    keys.forEach((key, column) => {
      const cell = excelSheet.cell(row + 1, column + 1)
      const headerCondition = length === 1 && !info[key]?.includes(':') && !info[key]?.includes(error) 
      const boldCondition = cell.rowNumber() == 3 || cell.rowNumber() == 1
      const styleCondition = length > 1
      cell.columnNumber() == 2 ? excelSheet.column(cell.columnNumber()).width(68) : excelSheet.column(cell.columnNumber()).width(32)
      cell.value(Number(info[key]) ? Number(info[key]) : info[key]).style({ horizontalAlignment: styleCondition && horizontalAlignment, verticalAlignment: 'top', wrapText: styleCondition, border: styleCondition, fontSize: headerCondition ? 16 : 12, bold: boldCondition })
    })
  })
}

const findErrorMessage = (info, valueToFind) => {
  const foundError = Object.entries(info).find(([key, value]) => value === valueToFind)
  return foundError
}

export function columnIndexToLetter(index) {
  let letter = ''
  while (index > 0) {
    const remainder = (index - 1) % 26
    letter = String.fromCharCode(65 + remainder) + letter
    index = Math.floor((index - 1) / 26)
  }
  return letter
}

export function splitStringOnCapitalization(value) {
  const valuesNotToBeSplitted = ['kBTU', 'kWh', 'kBTU/ft²', 'kWh/m²']
  if(typeof value === 'string' && !(/\d/).test(value) && !valuesNotToBeSplitted.includes(value)){
    const splitStringValue = value.split(' ')
    return splitStringValue.reduce((accumulator, currentValue) => {
      currentValue = currentValue === ' ' ? '_' : currentValue
      const text = currentValue === currentValue.toLocaleUpperCase() ? currentValue : currentValue.replace(CAPITAL_LETTER_REGEX, ' $1').trim()
      const updateValue = `${accumulator} ${text}`
      return updateValue
    }, '').trim()
  }
  return value
}

export const mergeMultipleCellRanges = (excelSheet, cellRanges) => {
  cellRanges.forEach(cellRange => {
    excelSheet.range(cellRange).merged(true)
  })
}

export const setRowHeight = (excelSheet, rowNumber, height) => {
  excelSheet.row(rowNumber).height(height)
}

export const applyFundReportPAITableHeaderStyles = (range, sheet) => {
  sheet.range(range).style([
    { bold: true, horizontalAlignment: 'left', verticalAlignment: 'center' },
    {
      fill: {
        type: 'solid',
        color: 'D9D9D9'
      }
    }
  ])
}

export const applyMultipleFundReportPAITableHeaderStyles = (ranges, sheet) => {
  ranges.forEach(range => {
    applyFundReportPAITableHeaderStyles(range, sheet)
  })
}

export const textAlignCenter = (excelSheet, range) => {
  excelSheet.range(range).style({ horizontalAlignment: 'center' })
}

export const getLicenseAttributionSheet = () => {
  let excelDataArray = []
  
  excelDataArray.push(...[{A: 'Disclaimer'}])
  excelDataArray.push(...getEmptyRows(1))
  excelDataArray.push(...[{A: 'This work is partially based on the Emissions Factors developed by the International Energy Agency, © OECD/IEA 2024'}])
  excelDataArray.push(...[{A: 'but the resulting work has been prepared by EVORA Global Limited and does not necessarily reflect the views of the International Energy Agency'}])
  
  return excelDataArray
}